import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInstagram,
    faFacebook,
    faYoutube,
    faTwitter,
    faLinkedin,
    faPinterest,
    faTiktok,
    faSnapchat,
    faReddit,
    faGithub
} from '@fortawesome/free-brands-svg-icons';
import { MdOutlineDelete } from 'react-icons/md';
import { IoAddSharp } from 'react-icons/io5';
import Layout from '../components/Layout';
import { getCookie } from '../utils/cookieUtils';

const getIcon = (url) => {
    if (url.includes('instagram.com')) return faInstagram;
    if (url.includes('facebook.com')) return faFacebook;
    if (url.includes('youtube.com')) return faYoutube;
    if (url.includes('twitter.com')) return faTwitter;
    if (url.includes('linkedin.com')) return faLinkedin;
    if (url.includes('pinterest.com')) return faPinterest;
    if (url.includes('tiktok.com')) return faTiktok;
    if (url.includes('snapchat.com')) return faSnapchat;
    if (url.includes('reddit.com')) return faReddit;
    if (url.includes('github.com')) return faGithub;
    return null;
};

const getSocialName = (url) => {
    if (url.includes('instagram.com')) return 'Instagram';
    if (url.includes('facebook.com')) return 'Facebook';
    if (url.includes('youtube.com')) return 'YouTube';
    if (url.includes('twitter.com')) return 'Twitter';
    if (url.includes('linkedin.com')) return 'LinkedIn';
    if (url.includes('pinterest.com')) return 'Pinterest';
    if (url.includes('tiktok.com')) return 'TikTok';
    if (url.includes('snapchat.com')) return 'Snapchat';
    if (url.includes('reddit.com')) return 'Reddit';
    if (url.includes('github.com')) return 'GitHub';
    return null;
};

const Socials = () => {
    const [socialLinks, setSocialLinks] = useState([]);
    const [editingLink, setEditingLink] = useState(null);
    const [newLink, setNewLink] = useState('');
    const { id } = useParams();

    const fetchSocialLinks = async () => {
        try {
            const response = await axios.get(`https://api.discoun3ree.com/api/shops/${id}/social-links`);
            const parsedLinks = JSON.parse(response.data.social_links || '[]');

            const formattedLinks = parsedLinks.map((url, index) => ({
                id: index + 1,
                url,
            }));

            setSocialLinks(formattedLinks);
        } catch (error) {
            console.error('Error fetching social links:', error);
        }
    };

    useEffect(() => {
        fetchSocialLinks();
    }, [id]);

    const addSocialLink = async () => {
        if (!newLink) return;

        try {
            const token = getCookie('access_token'); 
            const updatedSocialLinks = [...socialLinks.map(link => link.url), newLink];

            await axios.put(
                `https://api.discoun3ree.com/api/shops/${id}/social-links`,
                { social_links: updatedSocialLinks },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setNewLink('');
            fetchSocialLinks();
        } catch (error) {
            console.error('Error adding social link:', error);
        }
    };

    const updateSocialLink = async () => {
        const token = getCookie('access_token'); 
        if (!editingLink) return;

        try {
            const updatedLinks = socialLinks.map(link =>
                link.id === editingLink.id ? { ...link, url: editingLink.url } : link
            );

            await axios.put(
                `https://api.discoun3ree.com/api/shops/${id}/social-links`,
                { social_links: updatedLinks.map(link => link.url) },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setEditingLink(null);
            fetchSocialLinks();
        } catch (error) {
            console.error('Error updating social link:', error);
        }
    };

    const deleteSocialLink = async (shopId, linkToDelete) => {
        try {
            const token = getCookie('access_token'); 
            if (!accessToken) {
                throw new Error('Access token not found');
            }

            const response = await axios.delete(`https://api.discoun3ree.com/api/shops/${shopId}/social-links`, {
                data: { link: linkToDelete },
                headers: { Authorization: `Bearer ${token}` },
            });

            console.log('Link deleted successfully:', response.data);
            fetchSocialLinks();
        } catch (error) {
            console.error('Error deleting social link:', error);
        }
    };

    return (
        <Layout>
            <div className="w-full p-6 bg-gray-50 rounded-lg shadow-lg">
                <p className="mb-6 border-b pb-2 w-full border-gray-200 text-gray-700 font-semibold text-lg tracking-wide">
                    Social Media Links
                </p>

                <div className="mb-6 flex items-center space-x-3">
                    <input
                        type="url"
                        placeholder="Add a new social link..."
                        value={newLink}
                        onChange={(e) => setNewLink(e.target.value)}
                        className="flex-grow bg-white border border-gray-300 rounded-full px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition"
                    />
                    <button
                        onClick={addSocialLink}
                        className="bg-primary text-white p-2 rounded-full transition">
                        <IoAddSharp size={20} />
                    </button>
                </div>

                {socialLinks.length === 0 ? (
                    <p className="text-gray-500 text-sm text-center">No social links available. Add one above!</p>
                ) : (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        {socialLinks.map(link => (
                            <div key={link.id} className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition">
                                <div className="flex items-center space-x-3">
                                    {getIcon(link.url) && (
                                        <FontAwesomeIcon icon={getIcon(link.url)} className="text-primary" />
                                    )}
                                    <span className="font-medium text-gray-700">{getSocialName(link.url)}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <span className="truncate text-sm text-gray-500">
                                        {link.url}
                                    </span>
                                    <button
                                        onClick={() => deleteSocialLink(Number(id), link.url)}
                                        className="text-red-500 hover:text-red-600 transition">
                                        <MdOutlineDelete size={20} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {editingLink && (
                    <div className="mt-6 flex items-center space-x-3">
                        <input
                            type="url"
                            value={editingLink.url}
                            onChange={(e) => setEditingLink({ ...editingLink, url: e.target.value })}
                            className="flex-grow bg-white border border-gray-300 rounded-full px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition"
                        />
                        <button
                            onClick={updateSocialLink}
                            className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition">
                            Update
                        </button>
                    </div>
                )}
            </div>
        </Layout>

    );
};

export default Socials;
