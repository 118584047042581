import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { getShopReviews } from '../services/api';
import Loading from '../components/Loading';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [averageShopRating, setAverageShopRating] = useState(null);
    const [averageDiscountRating, setAverageDiscountRating] = useState(null);
    const [selectedReview, setSelectedReview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const fetchReviews = async () => {
            setIsLoading(true);
            try {
                const shopId = id;
                if (isNaN(shopId)) {
                    console.error('Invalid shop ID');
                    return;
                }

                const data = await getShopReviews(shopId);
                setReviews(data.all_reviews);
                setAverageShopRating(data.average_shop_rating);
                setAverageDiscountRating(data.average_discount_rating);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchReviews();
    }, [id]);

    const truncateBody = (body, length) => {
        return body.length > length ? body.substring(0, length) + '...' : body;
    };

    const handleReviewClick = (review) => {
        setSelectedReview(review);
    };

    const closeModal = () => {
        setSelectedReview(null);
    };

    return (
        <Layout>
            <div className="w-full flex flex-col space-y-6">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="flex flex-col md:flex-row justify-between items-center p-2 rounded-md bg-white space-y-4 -mb-2 md:space-y-0">
                            <p className="text-[20px] text-gray-900 font-normal">
                                Store Rating: <span className="text-primary">{averageShopRating}</span>
                            </p>
                            {averageDiscountRating !== null && (
                                <p className="text-lg text-gray-700 font-semibold">
                                    Average Discount Rating: <span className="text-primary">{averageDiscountRating}</span>
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {reviews.map((review) => (
                                <div
                                    key={review.id}
                                    className="p-4 bg-white rounded-lg shadow hover:shadow-md transition cursor-pointer"
                                    onClick={() => handleReviewClick(review)}
                                >
                                    <div className="flex flex-col space-y-2">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-medium text-gray-700">{review.user_name}</span>
                                            <span className="text-xs text-gray-500">{new Date(review.created_at).toLocaleDateString()}</span>
                                        </div>
                                        <p className="text-gray-600 text-sm">
                                            {truncateBody(review.body, 30)}
                                        </p>
                                        <div className="flex items-center justify-end">
                                            <span className="text-xs text-gray-500">Rating: <span className="text-primary">{review.rating}</span></span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {selectedReview && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-h-[90vh] overflow-y-auto md:w-1/2 lg:w-1/3">
                            <div className="flex justify-between items-center">
                                <p className="text-lg font-semibold text-gray-800 text-center w-full border-b pb-2">Review Details</p>
                                <button onClick={closeModal} className="text-gray-600 hover:text-gray-900">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-4 space-y-3">
                                <div className="flex flex-col space-y-1">
                                    <span className="text-gray-800 font-medium">User Name:</span>
                                    <p className="text-gray-600">{selectedReview.user_name}</p>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <span className="text-gray-800 font-medium">Posted:</span>
                                    <p className="text-gray-600">{new Date(selectedReview.created_at).toLocaleDateString()}</p>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <span className="text-gray-800 font-medium">Reviewed:</span>
                                    <p className="text-gray-600">{selectedReview.reviewable_type}</p>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <span className="text-gray-800 font-medium">Message:</span>
                                    <p className="text-gray-600">{selectedReview.body}</p>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <span className="text-gray-800 font-medium">Rating:</span>
                                    <p className="text-gray-600">{selectedReview.rating}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>

    );
};

export default Reviews;
