import './App.css';
import AppRoutes from './routes';

function App() {
  return (
    <div className='font-montserrat '>
      <AppRoutes />
    </div>
  );
}

export default App;
