import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, PointElement, Tooltip } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, PointElement, Tooltip);

const FollowersOverTimeChart = ({ shopId }) => {
    const [followersData, setFollowersData] = useState({ months: [], data: [] });

    useEffect(() => {
        const fetchFollowersData = async () => {
            const response = await fetch(`https://api.discoun3ree.com/api/shops/${shopId}/followers-over-time`);
            const result = await response.json();
            setFollowersData(result);
        };
        fetchFollowersData();
    }, [shopId]);

    const data = {
        labels: followersData.months,
        datasets: [
            {
                data: followersData.data,
                borderColor: '#FFD700', // Yellow color for the line
                backgroundColor: 'rgba(255, 215, 0, 0.2)', // Light yellow background fill
                fill: true,
                tension: 0.4, // Smooth the line
                pointRadius: 0, // Remove the dots on the line
                borderWidth: 2, // Make the line slightly thicker
                hoverRadius: 6, // Make the point larger when hovered for tooltip
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                enabled: true, // Enable tooltips
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Remove grid lines on x-axis
                },
            },
            y: {
                grid: {
                    display: false, // Remove grid lines on y-axis
                },
            },
        },
        hover: {
            mode: 'nearest', // Ensure tooltip appears nearest to the cursor
            intersect: false, // Tooltip appears even if not exactly on the line
        },
    };

    return (
        <div>
            <h2 className="text-[20px] font-medium mb-4">Monthly Followers</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default FollowersOverTimeChart;
