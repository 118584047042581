import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const AverageRatingsChart = ({ shopId }) => {
    const [ratingData, setRatingData] = useState({
        labels: ['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
        datasets: [{
            label: 'Ratings Distribution',
            data: [0, 0, 0, 0, 0],
            backgroundColor: [
                '#FF6384', // Red for 1 Star
                '#FF9F40', // Orange for 2 Stars
                '#FFCD56', // Yellow for 3 Stars
                '#4BC0C0', // Teal for 4 Stars
                '#36A2EB'  // Blue for 5 Stars
            ],
            hoverOffset: 4
        }]
    });

    useEffect(() => {
        const fetchRatingData = async () => {
            const response = await fetch(`https://api.discoun3ree.com/api/shops/${shopId}/rating-distribution`);
            const result = await response.json();

            setRatingData((prevData) => ({
                ...prevData,
                datasets: [{
                    ...prevData.datasets[0],
                    data: Object.values(result.rating_distribution)
                }]
            }));
        };
        fetchRatingData();
    }, [shopId]);

    return (
        <div className="flex">
            <div className="">
                <h2 className="text-2xl font-semibold mb-4">Rating Stats</h2>
                <div className="w-60">
                    <Pie data={ratingData} options={{ plugins: { legend: { display: false } } }} />
                </div>
            </div>
            <div className="ml-8 flex flex-col mt-8">
                {ratingData.labels.map((label, index) => (
                    <div key={index} className="flex items-center mb-2">
                        <div
                            className="w-4 h-4 rounded-full"
                            style={{ backgroundColor: ratingData.datasets[0].backgroundColor[index] }}
                        />
                        <span className="ml-2 text-gray-700 text-[13px]">{label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AverageRatingsChart;
