import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getCookie } from '../utils/cookieUtils';
import Layout from '../components/Layout';

const StaffList = () => {
    const [staff, setStaff] = useState([]);
    const [invites, setInvites] = useState([]);
    const [activeTab, setActiveTab] = useState('staff');
    const [modalOpen, setModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState(null);
    const { id } = useParams();
    const shopId = id;

    useEffect(() => {
        fetchStaff();
        fetchInvites();
    }, [shopId]);

    const fetchStaff = () => {
        const token = getCookie('access_token');
        axios.get(`https://api.discoun3ree.com/api/shops/${shopId}/staff`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                setStaff(response.data);
                setError(null);
            })
            .catch(error => {
                setError(error.response?.data?.message || 'An error occurred while fetching staff.');
                console.error(error);
            });
    };

    const fetchInvites = () => {
        const token = getCookie('access_token');
        axios.get(`https://api.discoun3ree.com/api/shops/${shopId}/invites`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                setInvites(response.data);
                setError(null);
            })
            .catch(error => {
                setError(error.response?.data?.message || 'An error occurred while fetching invites.');
                console.error(error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const token = getCookie('access_token');
        axios.post(`https://api.discoun3ree.com/api/shops/${shopId}/staff`, {
            email,
            position,
            role
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(() => {
                handleCloseModal();
                fetchStaff();
                fetchInvites();
            })
            .catch(error => {
                setError(error.response?.data?.message || 'An error occurred while adding staff.');
                console.error(error);
            });
    };

    const handleEdit = () => {
        const token = getCookie('access_token');
        axios.put(`https://api.discoun3ree.com/api/shops/${shopId}/staff/${selectedStaff.id}`, {
            email,
            position,
            role
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(() => {
                handleCloseEditModal();
                fetchStaff();
                fetchInvites();
            })
            .catch(error => {
                setError(error.response?.data?.message || 'An error occurred while editing staff.');
                console.error(error);
            });
    };

    const handleDelete = (staffId) => {
        const token = getCookie('access_token');
        axios.delete(`https://api.discoun3ree.com/api/shops/${shopId}/staff/${staffId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(() => {
                fetchStaff();
                fetchInvites();
            })
            .catch(error => {
                setError(error.response?.data?.message || 'An error occurred while deleting staff.');
                console.error(error);
            });
    };

    const handleAddStaff = () => {
        setModalOpen(true);
    };

    const handleViewStaff = (staff) => {
        setSelectedStaff(staff);
        setViewModalOpen(true);
    };

    const handleEditStaff = (staff) => {
        setSelectedStaff(staff);
        setEmail(staff.email);
        setPosition(staff.position);
        setRole(staff.role);
        setEditModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEmail('');
        setPosition('');
        setRole('');
        setError(null);
    };

    const handleCloseViewModal = () => {
        setViewModalOpen(false);
        setSelectedStaff(null);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setEmail('');
        setPosition('');
        setRole('');
        setSelectedStaff(null);
        setError(null);
    };

    return (
        <Layout>
            <div className="w-full gap-4 flex flex-col">
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-semibold text-gray-800 flex items-center gap-2">Staff <span className="hidden md:flex">Management</span></h1>
                    <button
                        className="px-6 py-1.5 bg-primary text-white rounded-md hover:shadow-ld transition-transform transform hover:scale-105"
                        onClick={handleAddStaff}
                    >
                        Add Staff
                    </button>
                </div>

                <div className="flex space-x-4">
                    <button
                        className={`py-1.5 px-4 text-[14px] font-medium rounded-md focus:outline-none ${activeTab === 'staff' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-600'}`}
                        onClick={() => setActiveTab('staff')}
                    >
                        All Staff
                    </button>
                    <button
                        className={`py-1.5 px-4 text-[14px] font-medium rounded-md focus:outline-none ${activeTab === 'invites' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-600'}`}
                        onClick={() => setActiveTab('invites')}
                    >
                        Pending Invites
                    </button>
                </div>

                {activeTab === 'staff' && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {staff.map((member) => (
                            <div key={member.id} onClick={() => handleViewStaff(member)} className="cursor-pointer bg-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform w-full">
                                <p className="">{member.first_name} {member.last_name}</p>
                                <p className="">{member.email}</p>
                                <div className="flex items-center">
                                    <h3 className="text-[14px] font-semibold text-gray-800">{member.position}</h3>
                                    <span className="px-2 font-medium">&middot;</span>
                                    <p className="text-gray-600">{member.role}</p>
                                </div>
                                <div className="mt-4 flex justify-between items-center">
                                    <button className="text-yellow-500 hover:underline" onClick={() => handleEditStaff(member)}>Edit</button>
                                    <button className="text-red-500 hover:underline" onClick={() => handleDelete(member.id)}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {activeTab === 'invites' && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {invites.map((invite) => (
                            <div key={invite.id} className="bg-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
                                <h3 className="text-[14px] font-semibold text-gray-800">{invite.first_name} {invite.last_name}</h3>
                                <p className="text-gray-600">{invite.email}</p>
                                <p className="text-gray-600">{invite.phone}</p>
                                <p className="text-gray-600">{invite.position}</p>
                            </div>
                        ))}
                    </div>
                )}

                {modalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
                            <h3 className="text-xl font-semibold text-center text-gray-800">Add New Staff</h3>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-gray-700">Email</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Position</label>
                                    <input
                                        type="text"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Role</label>
                                    <input
                                        type="text"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        onClick={handleCloseModal}
                                        className="px-4 py-2 text-gray-600 hover:text-gray-900"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-1.5 bg-primary text-white rounded-lg shadow-lg hover:shadow-xl"
                                    >
                                        Add Staff
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {viewModalOpen && selectedStaff && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
                            <h3 className="text-xl font-semibold text-center text-gray-800">Staff Details</h3>
                            <div className="space-y-4">
                                <p><strong>Email:</strong> {selectedStaff.email}</p>
                                <p><strong>Position:</strong> {selectedStaff.position}</p>
                                <p><strong>Role:</strong> {selectedStaff.role}</p>
                                <p><strong>Open Time:</strong> {selectedStaff.open_time}</p>
                                <p><strong>Close Time:</strong> {selectedStaff.close_time}</p>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={handleCloseViewModal}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-900"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {editModalOpen && selectedStaff && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
                            <h3 className="text-xl font-semibold text-center text-gray-800">Edit Staff</h3>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}
                            <form onSubmit={handleEdit} className="space-y-4">
                                <div>
                                    <label className="block text-gray-700">Email</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Position</label>
                                    <input
                                        type="text"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Role</label>
                                    <input
                                        type="text"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400"
                                    />
                                </div>
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        onClick={handleCloseEditModal}
                                        className="px-4 py-2 text-gray-600 hover:text-gray-900"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-gradient-to-r from-teal-400 to-blue-500 text-white rounded-lg shadow-lg hover:shadow-xl"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default StaffList;
