import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ChartComponent = ({ data }) => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const chartData = {
        labels: months,
        datasets: [
            {
                label: 'Appointments (services)',
                data: months.map((_, index) => data.appointments[String(index + 1).padStart(2, '0')] || 0),
                backgroundColor: 'rgba(34, 202, 236, 0.8)', 
                borderRadius: 3,
            },
            {
                label: 'Bookings (Offers)',
                data: months.map((_, index) => data.bookings[String(index + 1).padStart(2, '0')] || 0),
                backgroundColor: 'rgba(255, 159, 64, 0.8)', 
                borderRadius: 3,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Appointments Overview',
                position: 'top',
                align: 'start',
                font: {
                    size: 18,
                    weight: '600',
                },
                color: '#2D3748',
                padding: {
                    bottom: 20,
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: '#1A202C',  
                titleColor: '#fff',
                bodyColor: '#E2E8F0',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, 
                },
                ticks: {
                    color: '#4A5568', 
                },
            },
            y: {
                grid: {
                    display: false, 
                },
                ticks: {
                    color: '#4A5568',
                    beginAtZero: true,
                    stepSize: 1, 
                },
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default ChartComponent;
