import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { getCookie } from '../utils/cookieUtils';
import Loading from '../components/Loading';

const StaffInviteAcceptance = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const acceptInvite = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`https://api.discoun3ree.com/api/invites/accept/${token}`, null, {
                headers: {
                    Authorization: `Bearer ${getCookie('access_token')}`
                }
            });
            setMessage(response.data.message);
            setTimeout(() => navigate('/dashboard'), 3000);
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to accept the invitation. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-6 rounded shadow-md">
                <h1 className="text-xl font-semibold mb-4">{message || 'Do you want to accept the invitation?'}</h1>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {loading ? (
                    <Loading />
                ) : (
                    <button
                        onClick={acceptInvite}
                        className="px-6 py-2 bg-primary text-white rounded-md"
                    >
                        Accept Invite
                    </button>
                )}
            </div>
        </div>
    );
};

export default StaffInviteAcceptance;
