import React from 'react';
import { BsChatDots } from 'react-icons/bs';
import { CiBookmark, CiCalendarDate, CiEdit, CiGlobe } from 'react-icons/ci';
import { FaRegStar } from 'react-icons/fa';
import { FiUsers } from "react-icons/fi"; 
import { IoHomeOutline } from 'react-icons/io5';
import { MdOutlineDashboardCustomize, MdOutlineDiscount, MdOutlineMiscellaneousServices, MdPendingActions } from 'react-icons/md';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

const Sidenav = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => location.pathname === path ? 'bg-[#F9EBD6]' : '';

    const logoutUser = () => {
        const domain = window.location.hostname === 'localhost' ? '' : '; domain=.discoun3ree.com';
        document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;${domain}; secure; SameSite=None`;
        navigate('/');
        window.location.reload();
    };

    return (
        <div className='w-[15%] md:w-[20%] bg-[#fff] hidden md:flex flex-col pl-[2%] md:pl-[5%] h-[92vh] py-3 md:pt-4 md:pb-8 pr-[1.5%]'>
            <Link to={`/`} className={`flex items-center mt-4 gap-2 p-2 rounded-md ${isActive('/')}`}>
                <IoHomeOutline />
                <span className='flex text-[#242220] text-[14px]'>Home</span>
            </Link>
            <Link to={`/merchant/${id}/dashboard`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/dashboard`)}`}>
                <MdOutlineDashboardCustomize />
                <span className='flex text-[#242220] text-[14px]'>Dashboard</span>
            </Link>
            <Link to={`/merchant/${id}/offers`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/offers`)}`}>
                <MdOutlineDiscount />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Offers</span>
            </Link>
            <Link to={`/merchant/${id}/services`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/services`)}`}>
                <MdOutlineMiscellaneousServices />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Services</span>
            </Link>
            <Link to={`/merchant/${id}/appointments`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/appointments`)}`}>
                <CiBookmark />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Appointments</span>
            </Link>
            <Link to={`/merchant/${id}/pending`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/pending`)}`}>
                <MdPendingActions />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Pending</span>
            </Link>
            <Link to={`/merchant/${id}/chat`} className={`flex items-center gap-2 p-2 rounded-md ${isActive('/chat')}`}>
                <BsChatDots />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Chat</span>
            </Link>
            <Link to={`/merchant/${id}/reviews`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/reviews`)}`}>
                <FaRegStar />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Reviews</span>
            </Link>
            <Link to={`/merchant/${id}/socials`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/socials`)}`}>
                <CiGlobe />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Socials</span>
            </Link>
            <Link to={`/merchant/edit/${id}`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/edit/${id}`)}`}>
                <CiEdit />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Edit</span>
            </Link>
            <Link to={`/merchant/${id}/staff`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/staff`)}`}>
                <FiUsers />
                <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Staff</span>
            </Link>
            <button onClick={logoutUser} className="flex items-center mt-4 bg-primary text-white text-[14px] font-medium gap-2 p-2 rounded-md text-center w-full mx-auto justify-center">
                Log Out
            </button>
        </div>
    );
}

export default Sidenav;
