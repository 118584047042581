import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ChartComponent from './ChartComponent';
import { useParams } from 'react-router-dom';

const ShopAnalytics = () => {
    const [data, setData] = useState(null);
    const { id } = useParams();
    const shopId = id;

    useEffect(() => {
        axios.get(`https://api.discoun3ree.com/api/shop/${shopId}/analytics`)
            .then(response => setData(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, [shopId]);

    if (!data) return <p>Loading...</p>;

    return <div className="h-full flex">
        <ChartComponent data={data} />
    </div>;
};

export default ShopAnalytics;
