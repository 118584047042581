import React, { useEffect, useState } from 'react';
import DiscountTable from './Discounts/DiscountTable';
import DiscountForm from './Discounts/DiscountForm';
import DiscountPopup from './Discounts/DiscountPopup';
import Layout from '../components/Layout';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getCookie } from '../utils/cookieUtils';

const DiscountsPage = () => {
    const [discounts, setDiscounts] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const { id } = useParams()
    const [discountData, setDiscountData] = useState({
        name: '',
        initial_price: '',
        discount: '',
        expiry_date: '',
        service_time_hours: '',
        category: '',
        description: '',
        image: '',
        shop_id: id ? parseInt(id) : 0,
    });

    const handleRowClick = (discount) => {
        setSelectedDiscount(discount);
    };

    const handleFormOpen = () => {
        setFormOpen(true);
    };

    const handleClose = () => {
        setFormOpen(false);
        setDiscountData({
            name: '',
            initial_price: '',
            discount: '',
            expiry_date: '',
            service_time_hours: '',
            category: '',
            description: '',
            image: '',
            shop_id: id ? parseInt(id) : 0,
        });
        setImageUrl('');
    };

    useEffect(() => {
        const fetchDiscounts = async () => {
            try {
                const token = getCookie('access_token'); 
                const response = await axios.get(`https://api.discoun3ree.com/api/shops/${id}/discounts`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDiscounts(response.data);
            } catch (error) {
                console.error('Error fetching discounts:', error);
            }
        };

        fetchDiscounts();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const token = getCookie('access_token'); 
            setIsLoading(true);

            await axios.post('https://api.discoun3ree.com/api/discounts', {
                ...discountData,
                image_url: imageUrl,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setDiscountData({
                id: 0,
                name: '',
                initial_price: 0,
                discount: 0,
                expiry_date: '',
                service_time_hours: 0,
                image_url: imageUrl,
                category: '',
                description: '',
                shop_id: parseInt(id ?? '0')
            });
            setImageUrl('');
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            console.error('Error adding discount:', error);
            setIsLoading(false);
        }
        setIsLoading(false);
        handleClose();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDiscountData({
            ...discountData,
            [name]: value
        });
    };

    const handleImageChange = async (e) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            setLoadingImage(true);
            try {
                const formData = new FormData();
                formData.append('image', file);

                const response = await axios.post('https://api.discoun3ree.com/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setImageUrl(response.data.url);
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setLoadingImage(false);
            }
        }
    };

    return (
        <Layout>
            <div className="flex flex-col w-full">
                <button onClick={handleFormOpen} className="w-fit px-6 text-[13px] font-medium bg-primary text-white py-1.5 rounded-md mb-2">Add new offer</button>
                <DiscountTable discounts={discounts} handleRowClick={handleRowClick} isLoading={isLoading} />
                {formOpen && (
                    <DiscountForm
                        handleSubmit={handleSubmit}
                        handleInputChange={handleInputChange}
                        handleImageChange={handleImageChange}
                        handleClose={handleClose}
                        discountData={discountData}
                        imageUrl={imageUrl}
                        loading={loadingImage}
                        isLoading={isLoading}
                    />
                )}
                <DiscountPopup discount={selectedDiscount} onClose={() => setSelectedDiscount(null)} />
            </div>
        </Layout>
    );
};

export default DiscountsPage;
