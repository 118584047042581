import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "../utils/cookieUtils";

const BASE_URL = 'https://api.discoun3ree.com/api';

const getToken = () => getCookie('access_token');

const getHeaders = (includeAuth = true) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (includeAuth) {
    const token = getToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error('Access token not found in localStorage');
      throw new Error('Access token not found');
    }
  }

  return headers;
};

export const approveBooking = async (bookingId, code) => {
  try {
    const response = await axios.put(`${BASE_URL}/bookings/${bookingId}/approve`, { code }, {
      headers: getHeaders(),
    });
    toast.success('Booking approved successfully!');
    return response.data;
  } catch (error) {
    console.error('Error approving booking:', error);
    toast.error('An error occurred while approving the booking.');
    throw error;
  }
};

export const getUnverifiedDiscountsByShop = async (shopId) => {
  try {
    const response = await axios.get(`${BASE_URL}/shops/${shopId}/unverified-discounts`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching unverified discounts:', error);
    toast.error('An error occurred while fetching unverified discounts.');
    throw error;
  }
};

export const getShopReviews = async (shopId) => {
  try {
    const response = await axios.get(`${BASE_URL}/shops/${shopId}/reviews`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const fetchOwnerStores = async (token, setStores) => {
  try {
    const response = await axios.get(`https://api.discoun3ree.com/api/user/shops`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setStores(response.data);
  } catch (error) {
    console.error('Error fetching stores:', error);
  }
};


export const addStore = async (storeData, setLoading, handleCloseAddStore) => {
  const token = getCookie('access_token'); 

  setLoading(true);
  try {
    const response = await axios.post('https://api.discoun3ree.com/api/shops', storeData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      toast.success("Success!");
      navigate(`/`);
      window.location.reload();
    } else {
      toast.error("An error occured")
    }

  } catch (error) {
    toast.error("An error occurred!");
  } finally {
    setLoading(false);
    handleCloseAddStore();
  }
};

export const handleImageChange = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post('https://api.discoun3ree.com/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data.url;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};
