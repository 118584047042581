import React, { ReactNode } from 'react';
import Topmenu from './Topmenu';
import Sidenav from './Sidenav';

const Layout = ({ children }) => {
    return (
        <div>
            <Topmenu />
            <div className="flex">
                <Sidenav />
                <div className="flex p-4 md:py-8 md:pl-8 md:pr-[5%] h-[92vh] overflow-y-auto w-full md:w-[80%] bg-gray-100 ">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
