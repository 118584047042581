import React, { useEffect, useState } from 'react'
import logo from '../assets/icon.png';
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useAuth } from '../contex/AuthContext';
import { fetchOwnerStores } from '../services/api';
import { getCookie } from '../utils/cookieUtils';
import { FiMenu, FiX } from 'react-icons/fi';
import one from '../assets/merch1.jpg';
import two from '../assets/merch2.png';
import three from '../assets/merch3.png';
import four from '../assets/merch4.jpg';

const Index = () => {
    const [stores, setStores] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const { user } = useAuth();
    const token = getCookie('access_token');    

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMapClick = () => {
        window.open('https://www.google.com/maps/search/?api=1&query=Le\'Mac', '_blank');
    };
    const currentYear = new Date().getFullYear();

    const handleEmailClick = () => {
        window.open('mailto:info@dtree.com', '_blank');
    };
    const handleCategoryClick = (category) => {
        const searchUrl = `https://discoun3ree.com/search?query=${category}`;

        window.location.href = searchUrl;
    };

    useEffect(() => {
        if (token) {
            fetchOwnerStores(token, setStores);
        }
    }, [token]);

    return (
        <div className='flex flex-col tracking-wider'>
            <div className="w-full px-[10%] flex flex-col md:flex-row py-2 relative items-center md:justify-between bg-gradient-to-r from-yellow-50 to-primary">
                <div className="w-full flex justify-between items-center">
                    <a href="/">
                        <img src={logo} className='h-[55px]' alt="Logo" />
                    </a>
                    <div className="md:hidden">
                        <button onClick={toggleMenu}>
                            {menuOpen ? <FiX size={24} className="text-black" /> : <FiMenu size={24} className="text-black" />}
                        </button>
                    </div>
                </div>

                <div className={`hidden md:flex w-full items-center justify-end md:gap-4 text-start`}>
                    <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='https://discoun3ree.com' target=''>Home</Link>
                    <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='/'>Index</Link>
                    <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='htps://discoun3ree.com/deals'>All Offers</Link>
                    <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to={`/merchant/${stores[0]?.id}/offers`}>Your Offers</Link>
                    {user ? (
                        stores?.length !== 0 ?
                            <Link to={`/merchant/${stores[0]?.id}/dashboard`} className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Portal</Link>
                            :
                            <Link to='/merchant/set-up-business' className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Set Up Business</Link>
                    ) : (
                        <>
                            <Link to='/accounts/login' className='text-white ml-4 text-[14px] font-medium hover:underline hover:text-white'>Login</Link>
                            <Link to='/accounts/register' className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Sign Up</Link>
                        </>
                    )}
                </div>
                {menuOpen && (
                    <div className="flex md:hidden w-full px-[10%] py-4 justify-between text-end bg-gradient-to-r from-yellow-50 to-primary absolute top-full right-0">
                        <div className="">
                            <p className=""></p>
                        </div>
                        <div className="flex flex-col w-fit gap-2">
                            <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='https://discoun3ree.com' target=''>Home</Link>
                            <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='/'>Index</Link>
                            <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' to='htps://discoun3ree.com/deals' target=''>All Offers</Link>
                            <Link className='text-black text-[14px] font-medium hover:underline hover:text-white' tto={`/merchant/${stores[0]?.id}/offers`}>Your Offers</Link>
                            {user ? (
                                stores?.length !== 0 ?
                                    <Link to={`/merchant/${stores[0]?.id}/dashboard`} className='text-black w-fit text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Portal</Link>
                                    :
                                    <Link to='/merchant/set-up-business' className='text-black text-[14px] w-fit font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Set Up Business</Link>
                            ) : (
                                <div className='flex flex-col w-full justify-end text-end gap-2'>
                                    <Link to='/accounts/login' className='text-white text-[14px] text-end justify-end w-full font-medium hover:underline hover:text-white'>Login</Link>
                                    <Link to='/accounts/register' className='text-black text-[14px] w-fit font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md'>Sign Up</Link>
                                </div>
                            )}
                        </div>
                    </div>

                ) }
            </div>
            <div className="bg-gray-900 px-[10%] py-8 flex flex-col md:flex-row">
                <div className="w-full md:w-[50%] flex flex-col gap-2">
                    <p className="text-white capitalize font-bold text-[50px]">Boost Your Visibility <br /> with Exclusive Offers</p>
                    <p className="text-gray-50 font-light">Attract more customers and enhance your business' visibility with our powerful discount platform. We connect you with a wider audience, driving traffic and increasing your brand's presence.</p>
                    <p className="text-gray-50 font-light">Our service includes comprehensive listings and cutting-edge appointment software to streamline your operations. Stand out in the crowded market and offer irresistible deals to your customers.</p>
                    <button className="mt-4 w-fit bg-primary px-6 py-1.5 text-[14px] font-medium text-white outline-none rounded-md">Get started</button>
                </div>
                <div className="w-full md:w-[50%]">
                    <img src={two} alt="Discounts Illustration" />
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-8">
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Modernized Search</p>
                    <p className="`text-black text-[34px] font-semibold">Elevate Your business' Reach</p>
                    <p className="font-light text-[15px]">Our platform simplifies the process of getting noticed by potential customers. With our advanced search features, your discounts and services will be prominently featured, making it easier for people to find and choose your business.</p>
                </div>
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src={one} alt="Search Enhancement" />
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-16">
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src={four} alt="Increased Reach" />
                </div>
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Increased Reach</p>
                    <p className="text-black text-[34px] font-semibold">Expand Your Customer Base</p>
                    <p className="font-light text-[15px]">Our platform helps you reach a broader audience and attract more potential clients. By leveraging our network and tools, you'll see a noticeable increase in customer engagement and conversion.</p>
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-8">
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Manage Appointments</p>
                    <p className="f text-black text-[34px] font-semibold">Streamlined Scheduling Software</p>
                    <p className="font-light text-[15px]">Our customizable scheduling software is designed to fit your business' needs. Efficiently manage appointments and reduce no-shows with our intuitive and easy-to-use system.</p>
                </div>
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src="https://images.unsplash.com/photo-1633526543814-9718c8922b7a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGFwcG9pbnRtZW50fGVufDB8MHwwfHx8Mg%3D%3D" alt="Appointment Management" />
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-16">
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src={two} alt="Service Listings" />
                </div>
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Comprehensive Service Listings</p>
                    <p className=" text-black text-[34px] font-semibold">Showcase Your Services Effectively</p>
                    <p className="font-light text-[15px]">Highlight your services with detailed listings that attract potential customers. Our platform allows you to create comprehensive service profiles that showcase your offerings, including pricing, descriptions, and special features.</p>
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-16">
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Targeted Promotions</p>
                    <p className="text-black text-[34px] font-semibold">Reach the Right Audience</p>
                    <p className="font-light text-[15px]">Our platform provides tools for creating targeted promotions that resonate with your ideal customer. Use our analytics to identify trends and tailor your offers to maximize impact and drive sales.</p>
                </div>
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src={four} alt="Targeted Promotions" />
                </div>
            </div>

            <div className="bg-white flex flex-col items-center gap-6 md:flex-row px-[10%] py-16">
                <div className="w-full md:w-[50%]">
                    <img className='shadow-lg rounded-md' src="https://images.unsplash.com/photo-1485622204874-8ee4a42c4969?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y3VzdG9tZXJ8ZW58MHx8MHx8fDA%3D" alt="Customer Engagement" />
                </div>
                <div className="flex w-full md:w-[50%] flex-col">
                    <p className="uppercase text-primary font-medium text-[16px]">Enhanced Customer Engagement</p>
                    <p className=" text-black text-[34px] font-semibold">Build Stronger Customer Relationships</p>
                    <p className="font-light text-[15px]">Strengthen your customer relationships with personalized communication and engagement strategies. Our platform offers tools for managing customer feedback, loyalty programs, and more to keep your clients coming back.</p>
                </div>
            </div>

            <footer className="bg-white border-t border-gray-200 text-black">

                <div className="bg-white border-t py-4">
                    <div className="px-[5%] flex items-center justify-between text-gray-900">
                        <p>&copy; {currentYear} discoun3. All rights reserved.</p>
                        <div className="flex items-center gap-4 text-gray-600 text-[16px] ">
                            <div className="flex justify-center">
                                <a href="https://www.facebook.com/davtec.co?mibextid=ZbWKwL" target='_blank' className="mr-4 text-gray-500 hover:text-black">
                                    <FaFacebookF />
                                </a>
                                <a href="https://x.com/discoun3_" target='_blank' className="mr-4 text-gray-500 hover:text-black">
                                    <FaXTwitter />
                                </a>
                                <a href="https://www.instagram.com/discoun3_official" target='_blank' className="mr-4 text-gray-500 hover:text-black">
                                    <FaInstagram />
                                </a>
                                <a href="https://www.linkedin.com/company/discoun3-limited/" target='_blank' className="text-gray-500 hover:text-black">
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Index