import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const StepOne = ({ discountData, handleInputChange, goToNextStep }) => {
    const minExpiryDate = new Date();
    minExpiryDate.setDate(minExpiryDate.getDate() + 1);
    const minExpiryDateString = minExpiryDate.toISOString().split('T')[0];

    return (
        <div>
            <h2 className="text-[20px] font-medium mb-4 text-center text-black">Step 1: Offer Details</h2>
            <div className="space-y-4">
                <div>
                    <label htmlFor="name" className="text-[14px] text-black">Name Of Offer</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={discountData.name}
                        placeholder='e.g. Back to School Shoot'
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="initial_price" className="text-[14px] text-black">Initial Price</label>
                    <input
                        type="number"
                        id="initial_price"
                        name="initial_price"
                        value={discountData.initial_price}
                        placeholder='e.g. 5000'
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="text-[14px] text-black">Discount Amount</label>
                    <input
                        type="number"
                        id="discount"
                        name="discount"
                        value={discountData.discount}
                        placeholder='e.g. 1500'
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="expiry_date" className="text-[14px] text-black">Expiry Date</label>
                    <input
                        type="date"
                        id="expiry_date"
                        name="expiry_date"
                        value={discountData.expiry_date}
                        min={minExpiryDateString}
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="flex justify-end gap-2 mt-6">
                    <button
                        type="button"
                        className="px-6 py-1.5 rounded-md bg-primary text-white text-[14px] font-medium"
                        onClick={goToNextStep}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

const StepTwo = ({ discountData, handleInputChange, handleImageChange, handleClose, handleSubmit, imageUrl, isLoading, removeImage }) => {
    return (
        <div>
            <h2 className="text-[20px] font-medium mb-4 text-center text-black">Step 2: Additional Details</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="service_time_hours" className="text-[14px] text-black">Service Time (hrs)</label>
                    <input
                        type="number"
                        id="service_time_hours"
                        name="service_time_hours"
                        value={discountData.service_time_hours}
                        placeholder='e.g. 2'
                        className="p-3 block w-full text-[13px] last:font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="category" className="text-[14px] text-black">Category</label>
                    <input
                        type="text"
                        id="category"
                        name="category"
                        value={discountData.category}
                        placeholder='e.g. Beauty, Spa'
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="description" className="text-[14px] text-black">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={discountData.description}
                        placeholder='Enter discount description...'
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="image" className="text-[14px] text-black">Upload Image</label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                        onChange={handleImageChange}
                    />
                    {imageUrl && (
                        <div className="mt-4">
                            <img src={imageUrl} alt="Uploaded" className="rounded" />
                            <button type="button" className="text-red-500 mt-2 text-sm" onClick={removeImage}>Remove Image</button>
                        </div>
                    )}
                </div>
                <div className="flex justify-end gap-2 mt-6">
                    <button
                        type="button"
                        className="text-[14px] text-primary font-medium"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={`px-6 py-1.5 rounded-md bg-primary text-white text-[14px] font-medium ${isLoading ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </form>
        </div>
    );
};

const DiscountForm = ({ handleSubmit, handleInputChange, handleImageChange, handleClose, discountData, imageUrl, loading, isLoading, removeImage }) => {
    const [currentStep, setCurrentStep] = useState(1);

    const goToNextStep = () => {
        setCurrentStep(2);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white max-h-[90vh] overflow-auto relative rounded-lg shadow-lg p-8 w-full max-w-md">
                <button onClick={handleClose} className="absolute top-2 right-2 bg-yellow-100 p-1 rounded-full text-primary">
                    <svg className="h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
                {currentStep === 1 ? (
                    <StepOne
                        discountData={discountData}
                        handleInputChange={handleInputChange}
                        goToNextStep={goToNextStep}
                    />
                ) : (
                    <StepTwo
                        discountData={discountData}
                        handleInputChange={handleInputChange}
                        handleImageChange={handleImageChange}
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                        imageUrl={imageUrl}
                        isLoading={isLoading}
                        removeImage={removeImage}
                    />
                )}
            </div>
        </div>
    );
};

export default DiscountForm;
