import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { approveBooking } from '../services/api';
import Layout from '../components/Layout';
import { getCookie } from '../utils/cookieUtils';
import QRCodeScanner from '../utils/QRCodeScanner';
import { FaCamera, FaCameraRetro } from 'react-icons/fa';
import Loading from '../components/Loading';

const OwnerBookings = () => {
    const { id } = useParams();
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [approvalCode, setApprovalCode] = useState('');
    const [showScanner, setShowScanner] = useState(false);
    const [cameraFacing, setCameraFacing] = useState('environment');
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = getCookie('access_token');
                if (!token) {
                    throw new Error('No access token found');
                }

                const response = await axios.get(`https://api.discoun3ree.com/api/bookings/shop/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setBookings(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching bookings:', error);
                toast.error("An error occurred!");
                setLoading(false);
            }
        };

        fetchBookings();
    }, [id]);

    const handleDetailsClick = (booking) => {
        setSelectedBooking(booking);
        setShowDetailsModal(true);
    };

    const handleApproveClick = (booking) => {
        setSelectedBooking(booking);
        setShowApproveModal(true);
    };

    const handleApproveBooking = async () => {
        if (!selectedBooking || !approvalCode) {
            toast.error('Please enter an approval code.');
            return;
        }

        setLoadingApprove(true);
        try {
            await approveBooking(selectedBooking.id, approvalCode);
            toast.success('Booking approved successfully!');
            setShowApproveModal(false);
            setApprovalCode('');
            setSelectedBooking(null);
            window.location.reload();
        } catch (error) {
            toast.error('An error occurred.');
        } finally {
            setLoadingApprove(false);
        }
    };

    const handleScan = (data) => {
        if (data) {
            setApprovalCode(data);
            handleApproveBooking();
        }
    };

    const handleError = (error) => {
        console.error(error);
        toast.error('Error scanning QR code.');
    };

    const toggleCamera = () => {
        setCameraFacing(cameraFacing === 'environment' ? 'user' : 'environment');
    };

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
    };

    const filteredBookings = bookings.filter(
        (booking) =>
            booking.discount.name.toLowerCase().includes(filterText.toLowerCase()) ||
            `${booking.user.first_name} ${booking.user.last_name}`.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <Layout>
            <div className="flex w-full h-full flex-col">
                <div className="flex w-full justify-between itemsfilteredBookings-center mb-4">
                    <h1 className="text-[20px] font-medium text-gray-800">Appointments</h1>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={filterText}
                        onChange={handleFilterChange}
                        className="border w-fit bg-white outline-none px-4 py-1.5 rounded-md text-gray-700 text-[14px]"
                    />
                </div>
                <div className="w-full h-full">
                    {loading ? (
                        <div className="flex justify-center py-10">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {filteredBookings.map(booking => (
                                <div key={booking.id} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                    <div className="flex  items-center w-full justify-between mb-2">
                                        <p className="text-[17px] font-medium">{booking.discount.name}</p>
                                        <span className={`inline-block px-3 py-1 rounded-full text-[12px] font-normal ${booking.approved === 1 ? 'bg-green-200 text-green-900' : 'bg-yellow-200 text-yellow-900'}`}>
                                            {booking.approved === 1 ? 'Fulfilled' : 'Pending'}
                                        </span>
                                    </div>
                                    <p className="text-gray-700 text-[14px] mb-1">
                                        {`${booking.user.first_name} ${booking.user.last_name}`}
                                    </p>
                                    <p className="text-gray-700 text-[12px] mb-1">
                                        {moment(booking.time_slot.date).format('MMMM Do YYYY')}, {moment(booking.time_slot.start_time).format('h:mm A')} - {moment(booking.time_slot.end_time).format('h:mm A')}
                                    </p>
                                    <div className="flex justify-between items-center mt-4">
                                        <button
                                            className="text-blue-500 hover:underline focus:outline-none"
                                            onClick={() => handleDetailsClick(booking)}
                                        >
                                            Details
                                        </button>
                                        {booking.approved === 0 && (
                                            <button
                                                className="text-green-500 hover:underline focus:outline-none"
                                                onClick={() => handleApproveClick(booking)}
                                            >
                                                Confirm
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {showDetailsModal && selectedBooking && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-md w-[90%] md:w-[35%]">
                        <h2 className="text-lg font-semibold mb-4 text-center">Appointment Details</h2>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Name <span>{`${selectedBooking.user.first_name} ${selectedBooking.user.last_name}`}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Discount <span>{selectedBooking.discount.name}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Discount Amount <span>{selectedBooking.discount.discount}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Final Price <span>{selectedBooking.discount.price_after_discount}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Mobile <span>{selectedBooking.user.phone || 'N/A'}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Status <span>{selectedBooking.approved === 1 ? 'Fulfilled' : 'Pending'}</span></p>
                        <p className="text-sm text-gray-600 font-light flex items-center justify-between w-full border-b border-gray-200 mb-2 pb-0.5">Date <span>{moment(selectedBooking.time_slot.date).format('MMMM Do YYYY')
                        }, {moment(selectedBooking.time_slot.start_time).format('h:mm A')} - {moment(selectedBooking.time_slot.end_time).format('h:mm A')}</span></p>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setShowDetailsModal(false)}
                                className="bg-gray-200 text-gray-700 hover:bg-gray-300 px-4 py-2 rounded-md"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showApproveModal && selectedBooking && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-md w-[90%] md:w-[35%]">
                        <h2 className="text-lg font-semibold mb-4 text-center">Confirm Booking</h2>
                        <p className="text-sm text-gray-600 mb-4 text-center">Please enter a voucher code to confirm this appointment or use your phone to scan the QR code.</p>
                        {showScanner ? (
                            <div className="relative">
                                <QRCodeScanner
                                    onScan={handleScan}
                                    onError={handleError}
                                    facingMode={cameraFacing}
                                    className="w-full h-64 border border-gray-300 rounded-md"
                                />
                                <button
                                    onClick={toggleCamera}
                                    className="absolute top-4 right-4 border border-gray-300 rounded-md px-2 py-4"
                                >
                                    {cameraFacing === 'user' ? (
                                        <FaCamera />
                                    ) : (
                                        <FaCameraRetro />
                                    )}
                                </button>
                                {loadingApprove && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <Loading />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <input
                                type="text"
                                placeholder="Enter voucher code"
                                value={approvalCode}
                                onChange={(e) => setApprovalCode(e.target.value)}
                                    className="p-3 block w-full text-[13px]  font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none mb-4"
                            />
                        )}
                        <div className="flex flex-col">
                            <div className="flex items-center justify-end gap-2">
                                <button
                                    onClick={() => setShowApproveModal(false)}
                                    className="text-primary font-medium text-[13px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleApproveBooking}
                                    disabled={loadingApprove}
                                    className={`bg-primary text-white font-medium text-[13px] px-6 py-1.5 rounded-md ${loadingApprove ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {loadingApprove ? 'Please wait...' : 'Confirm'}
                                </button>                                
                            </div>
                            <div className="flex items-center w-full gap-1">
                                <hr className='w-full' />
                                <p className="text-center my-2 text-gray-500 text-[13px]">Or</p>
                                <hr className='w-full' />
                            </div>
                            <button
                                onClick={() => setShowScanner(!showScanner)}
                                className={`bg-primary px-4 py-1.5 text-[13px] font-medium text-white rounded-md ${showScanner ? 'ml-2' : ''}`}
                            >
                                {showScanner ? 'Enter Code' : 'Scan QR Code'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default OwnerBookings;
