import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceForm from './ServiceForm';
import { useParams } from 'react-router-dom';
import { FiEdit3 } from 'react-icons/fi';
import { FaRegEye } from 'react-icons/fa';
import ServiceDetails from './ServiceDetails';
import Layout from '../../components/Layout';

const ServicesList = () => {
    const [services, setServices] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(null);
    const [editService, setEditService] = useState(null);
    const { id } = useParams();
    const shopId = id;

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`https://api.discoun3ree.com/api/shops/${shopId}/services`);
                setServices(response.data);
            } catch (error) {
                console.error('Failed to fetch services', error);
            }
        };

        fetchServices();
    }, [shopId]);

    const handleFormSuccess = () => {
        fetchServices();
    };

    const fetchServices = async () => {
        try {
            const response = await axios.get(`https://api.discoun3ree.com/api/shops/${shopId}/services`);
            setServices(response.data);
        } catch (error) {
            console.error('Failed to fetch services', error);
        }
    };

    const handleEditClick = (service) => {
        setEditService(service);
        setShowForm(true);
    };

    const handleDetailsClick = (service) => {
        setShowDetails(service);
    };

    return (
        <Layout>
            <div className="w-full">
                <div className="w-full flex justify-between items-center">
                    <p className="text-[20px] font-medium">Services</p>
                    <button
                        onClick={() => setShowForm(true)}
                        className="bg-primary text-white font-medium text-[14px] py-1.5 px-6 rounded-md focus:outline-none focus:shadow-outline"
                    >
                        New Service
                    </button>
                </div>

                <div className="w-full flex flex-col pb-8 pt-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
                        {services.map(service => (
                            <div className="cursor-pointer shadow-sm flex flex-col w-full bg-white rounded-md p-4">
                                <div className="relative w-full h-24 overflow-hidden">
                                    <img
                                        src={service.image_url}
                                        alt=""
                                        className="absolute inset-0 w-full h-full object-cover rounded-md"
                                    />
                                </div>
                                <p className="text-[14px] text-black font-medium">{service.name}</p>
                                <p className="text-black font-medium text-[11px]">
                                    Kes {service.price.toLocaleString("KES")} <span className="mx-2">·</span> lasts {service.duration} minutes
                                </p>
                                <div className="flex w-full items-center gap-2 mt-2">
                                    <button onClick={() => handleEditClick(service)} className="w-full items-center py-1.5 text-white text-[13px] font-medium rounded-md bg-primary">Edit</button>
                                    <button onClick={() => handleDetailsClick(service)} className="w-full items-center py-1.5 text-white text-[13px] font-medium rounded-md bg-black">Details</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showForm && (
                    <ServiceForm
                        onClose={() => {
                            setShowForm(false);
                            window.location.reload();
                        }}
                        onSuccess={handleFormSuccess}
                        service={editService}
                    />
                )}
                {showDetails && (
                    <ServiceDetails
                        service={showDetails}
                        onClose={() => setShowDetails(null)}
                    />
                )}
            </div>
        </Layout>
    );
};

export default ServicesList;
