import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from './pages/Login'
import SignUp from './pages/SignUp'
import Dashboard from './components/Dashboard'
import DiscountsPage from './pages/DiscountsPage'
import EditableDiscountComponent from './pages/Discounts/EditableDiscountComponent'
import ServicesList from './pages/Services/ServicesList'
import OwnerBookings from './pages/OwnerBookings'
import Pending from './pages/Discounts/Pending'
import ChatPage from './pages/ChatPage'
import Reviews from './pages/Reviews'
import Socials from './pages/Socials'
import EditMerchant from './pages/EditMerchant'
import Index from './pages/Index'
import SetupBiz from './pages/SetupBiz'
import VerifyOtp from './pages/VerifyOTP'
import NotFoundPage from './pages/NotFoundPage'
import RequestPasswordReset from './pages/RequestPasswordReset'
import ResetPassword from './pages/ResetPassword'
import StaffList from './pages/StaffList'
import StaffInviteAcceptance from './pages/StaffInviteAcceptance'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Index />} />
      <Route path='/accounts/login' element={<LoginPage />} />
      <Route path='/accounts/register' element={<SignUp />} />
      <Route path='/accounts/verify-otp' element={<VerifyOtp />} />
      <Route path='/accounts/request-password-reset' element={<RequestPasswordReset />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path="/staff-accept-invite/:token" element={<StaffInviteAcceptance />} />
      <Route path='/merchant/set-up-business' element={<SetupBiz />} />

      <Route path='/merchant/:id/dashboard' element={<Dashboard />} />
      <Route path='/merchant/:id/offers' element={<DiscountsPage />} />
      <Route path='/offers/edit/:id' element={<EditableDiscountComponent />} />
      <Route path='/merchant/:id/services' element={<ServicesList />} />
      <Route path='/merchant/:id/appointments' element={<OwnerBookings />} />
      <Route path='/merchant/:id/pending' element={<Pending />} />
      <Route path='/merchant/:id/chat' element={<ChatPage />} />
      <Route path='/merchant/:id/reviews' element={<Reviews />} />
      <Route path='/merchant/:id/socials' element={<Socials />} />
      <Route path='/merchant/edit/:id' element={<EditMerchant />} />
      <Route path='/merchant/:id/staff' element={<StaffList />} />

      <Route path='*' element={<NotFoundPage />} />

    </Routes>
  )
}

export default AppRoutes