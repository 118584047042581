import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addStore, handleImageChange } from '../services/api';

const SetupBiz = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [storeData, setStoreData] = useState({
        name: '',
        location: '',
        store_type: '',
        image_url: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStoreData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            try {
                setLoading(true);
                const uploadedImageUrl = await handleImageChange(file);
                setStoreData(prevState => ({
                    ...prevState,
                    image_url: uploadedImageUrl
                }));
                setImageUrl(uploadedImageUrl);
            } catch (error) {
                toast.error("Error uploading image");
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await addStore(storeData, setLoading);
            toast.success("Business setup successfully");
            navigate('/');
            window.location.reload();
        } catch (error) {
            toast.error("Error setting up business");
        } finally {
            setLoading(false);
            navigate('/');
            window.location.reload();
        }
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white max-h-[90vh] overflow-auto rounded-lg shadow-lg p-8 w-[90%] md:w-[45%]">
                <h2 className="text-[20px] font-medium text-center text-gray-800">Set Up Your Business</h2>
                <p className="text-center mb-6 text-[14px] text-gray-600">Set up your business to start operations</p>
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name" className="block text-[14px] text-black">Business Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={storeData.name}
                            placeholder='e.g. QBF Software Solutions'
                            className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="location" className="block text-[14px] text-black">Location</label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={storeData.location}
                            placeholder='Enter exact location for easier accessibility.'
                            className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="store_type" className="block text-[14px] text-black">Business Type</label>
                        <input
                            type="text"
                            id="store_type"
                            name="store_type"
                            value={storeData.store_type}
                            placeholder='e.g. nail spa, makeup, etc...'
                            className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="image" className="block text-[14px] text-black">Upload Image</label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            className="p-3 block w-full text-[13px] font-light text-primary border-b border-gray-300 focus:border-primary focus:outline-none"
                            onChange={handleImageUpload}
                        />
                    </div>
                    {isLoading && (
                        <div className="flex items-center justify-center mt-2">
                            <div className="w-6 h-6 border-4 border-t-4 border-primary border-solid rounded-full animate-spin"></div>
                            <span className="ml-2">Uploading...</span>
                        </div>
                    )}
                    {imageUrl && (
                        <div>
                            <img src={imageUrl} alt="Uploaded" className="mt-4 w-full rounded" />
                        </div>
                    )}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="text-primary font-medium text-[14px] mr-4"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white px-6 py-1.5 rounded-md hover:bg-opacity-80"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Setting up...' : 'Set Up Biz'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SetupBiz;
