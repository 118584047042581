import React, { useEffect, useState } from 'react';
import { MdOutlineLoyalty } from 'react-icons/md';
import { CiBookmarkCheck } from 'react-icons/ci';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import PointsModal from './PointsModal';
import { useAuth } from '../contex/AuthContext';
import Layout from './Layout';
import ShopAnalytics from './ShopAnalytics';
import { getCookie } from '../utils/cookieUtils';
import FollowersOverTimeChart from './FollowersOverTime';
import AverageRatingsChart from './AverageRatingsChart';

const Dashboard = () => {
    const [discounts, setDiscounts] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [shop, setShop] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const { user } = useAuth();
    const { id } = useParams();

    useEffect(() => {
        const fetchDiscounts = async () => {
            try {
                const token = getCookie('access_token'); 
                const response = await axios.get(`https://api.discoun3ree.com/api/shops/${id}/discounts`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDiscounts(response.data);
            } catch (error) {
                console.error('Error fetching discounts:', error);
            }
        };

        fetchDiscounts();
    }, [id]);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = getCookie('access_token'); 
                if (!token) {
                    throw new Error('No access token found');
                }

                const response = await axios.get(`https://api.discoun3ree.com/api/bookings/shop/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setBookings(response.data.data);
            } catch (error) {
                console.error('Error fetching bookings:', error);
                toast.error("An error occurred!")
            }
        };

        fetchBookings();
    }, [id]);

    useEffect(() => {
        fetchShopInfo();
    }, [id]);

    const fetchShopInfo = async () => {
        try {
            const accessToken = getCookie('access_token'); 

            if (!accessToken) {
                throw new Error('No access token found');
            }

            const response = await axios.get(`https://api.discoun3ree.com/api/shops/${id}/see`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setShop(response.data);
        } catch (error) {
            console.error('Failed to fetch shop information:', error);
        }
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        const day = date.getDate();
        const daySuffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return formattedDate.replace(day, day + daySuffix);
    };


    return (
        <Layout>
            <div className="flex flex-col w-full tracking-wide pb-6">
                <div className="border-b flex w-full items-center justify-between border-gray-200 ">
                    <span>
                        What's up <span className="text-black font-medium text-[18px] uppercase">{user?.first_name}</span>!
                    </span>
                    <Link to={`/merchant/edit/${id}`} className="font-medium text-primary text-[15px] hover:text-[17px] transition ease-in-out dekat-300">Update shop info</Link>
                </div>
                <div className="flex w-full justify-between py-6 flex-col md:flex-row gap-4">
                    <div onClick={openModal} className="bg-primary w-full p-6 rounded-xl items-center flex gap-4 cursor-pointer">
                        <div className="rounded-md text-gray-600 bg-gray-100 flex items-center justify-center p-1">
                            <MdOutlineLoyalty />
                        </div>
                        <div className="flex flex-col w-full gap-3">
                            <p className="font-medium text-white text-[12px] tracking-wide">Reward Points</p>
                            <hr className='w-full bg-gray-600' />
                            <p className="text-white text-[17px]"><span className="font-medium text-[17px]">{shop?.loyalty_points}</span></p>
                        </div>
                    </div>
                    <div className="bg-[#F9EBD6] w-full p-6 items-center rounded-xl flex gap-4">
                        <div className="rounded-md text-gray-600 bg-gray-100 flex items-center justify-center p-1">
                            <CiBookmarkCheck />
                        </div>
                        <div className="flex flex-col text-light gap-3 w-full">
                            <p className="font-medium text-gray-600 text-[12px] tracking-wide">Total Bookings</p>
                            <hr className='w-full bg-gray-600' />
                            <p className="text-gray-600 text-[17px]"><span className="font-medium text-[17px]">{bookings.length}</span></p>
                        </div>
                    </div>
                </div>
                <p className="text-gray-700 font-medium text-[14px]">Working days</p>
                <div className="flex flex-col w-full bg-white mb-4 rounded-md p-2">
                    {shop?.working_days && shop.working_days.length > 0 ? (
                        <div className="flex w-full items-center flex-wrap gap-6">
                            {shop.working_days.map(day => (
                                <div key={day} className="flex w-fit">
                                    <span className="text-gray-600 font-normal text-[13px]">{day}</span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex w-full items-center justify-between">
                            <span className="text-gray-600 font-normal text-[13px]">No working days set</span>
                            <Link to={`/merchant/edit/${shop?.id}`} className="text-primary text-[14px] font-medium hover:underline">
                                Edit Working Days
                            </Link>
                        </div>
                    )}
                </div>
                <div className="flex flex-col w-full md:flex-row mb-4 gap-4 ">
                    <div className="flex flex-col p-4 w-full gap-1.5 bg-white rounded-md">
                        <p className="text-gray-600 font-light text-[13px]">Opening time</p>
                        <hr />
                        <p className="text-gray-700 text-[14px] font-medium">{shop?.open_time}</p>
                    </div>
                    <div className="flex flex-col p-4 w-full gap-1.5 bg-white rounded-md">
                        <p className="text-gray-600 font-light text-[13px]">Closing time</p>
                        <hr />
                        <p className="text-gray-700 text-[14px] font-medium">{shop?.close_time}</p>
                    </div>
                    <div className="flex flex-col p-4 w-full gap-1.5 bg-white rounded-md">
                        <p className="text-gray-600 font-light text-[13px]">Offers</p>
                        <hr />
                        <p className="text-gray-700 text-[14px] font-medium">{discounts.length}</p>
                    </div>
                    <div className="flex flex-col p-4 w-full gap-1.5 bg-white rounded-md">
                        <p className="text-gray-600 font-light text-[13px]">Services</p>
                        <hr />
                        <p className="text-gray-700 text-[14px] font-medium">8</p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 mb-4 ">
                    <div className="w-full md:w-[60%] bg-white rounded-lg p-4">
                        <FollowersOverTimeChart shopId={id} /> 
                    </div>
                    <div className="w-full md:w-[40%] bg-white rounded-lg p-4">
                        <AverageRatingsChart shopId={id} />
                    </div>
                </div>
                <div className="flex flex-col w-full gap-4 h-full md:flex-row mb-8">
                    <div className="w-full bg-white rounded-md p-4 h-full md:w-[60%]">
                        <ShopAnalytics />
                    </div>
                    <div className="w-full bg-white rounded-md h-full md:w-[40%] p-4">
                        <div className="flex w-full items-center justify-between mb-3 border-b border-gray-300">
                            <p className="">Recents</p>
                            <button className="">View all</button>
                        </div>
                        <div className="flex flex-col gap-2">
                            {bookings.slice(0, 5).map(booking => (
                                <div className="w-full flex items-center justify-between">
                                    <div className="flex flex-col">
                                        <p className="text-black font-medium text-[14px]">{booking.user.first_name} {booking.user.last_name}</p>
                                        <p className="text-black font-light text-[12px]">{booking.discount.name}</p>
                                    </div>
                                    <div className="flex text-end flex-col">
                                        <p className="text-[14px] text-black">{booking.discount.price_after_discount}</p>
                                        <p className="text-gray-600 text-[12px]">{formatDate(booking.time_slot.start_time)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <PointsModal isOpen={isModalOpen} onClose={closeModal} points={Number(shop?.loyalty_points)} />
            </div>
        </Layout>
    );
};

export default Dashboard;
