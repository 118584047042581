import React from 'react';

const DiscountPopup = ({ discount, onClose }) => {
    if (!discount) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
            <div className="bg-white max-h-[90vh] overflow-auto relative rounded-xl shadow-2xl p-8 w-full max-w-lg">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors"
                >
                    <svg
                        className="h-5 w-5 text-gray-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <h2 className="text-xl font-semibold mb-6 text-center text-gray-900">Discount Details</h2>
                <div className="space-y-4">
                    <div className="text-sm text-gray-600">
                        <span className="block text-gray-800">Name:</span> {discount.name}
                    </div>
                    <div className="text-sm text-gray-600">
                        <span className="block text-gray-800">Price:</span> {discount.initial_price}
                    </div>
                    <div className="text-sm text-gray-600">
                        <span className="block text-gray-800">Discount:</span> {discount.discount}
                    </div>
                    <div className="text-sm text-gray-600">
                        <span className="block text-gray-800">Expiry Date:</span> {new Date(discount.expiry_date).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-gray-600">
                        <span className="block text-gray-800">Description:</span> {discount.description}
                    </div>
                    {discount.image_url && (
                        <div className="relative w-full h-24 mt-4 overflow-hidden">
                            <img
                                src={discount.image_url}
                                alt=""
                                className="absolute inset-0 w-full h-full object-cover rounded-md"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DiscountPopup;
