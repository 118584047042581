import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import { FaCamera, FaCameraRetro } from 'react-icons/fa';

const QRCodeScanner = ({ onScan, onError }) => {
  const webcamRef = useRef(null);
  const [cameraFacing, setCameraFacing] = useState('environment');

  useEffect(() => {
    const scan = () => {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        const image = new Image();
        image.src = imageSrc;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);
          if (code) {
            onScan(code.data);
          }
        };
      }
    };

    const interval = setInterval(scan, 1000);

    return () => clearInterval(interval);
  }, [onScan]);

  const toggleCamera = () => {
    setCameraFacing(cameraFacing === 'user' ? 'environment' : 'user');
  };

  return (
    <div className="scanner-container">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{ facingMode: cameraFacing }}
        width="100%"
        height="100%"
      />
      <button
          onClick={toggleCamera}
          className="absolute top-4 right-4 border border-gray-300 rounded-md px-2 py-4"
      >
          {cameraFacing === 'user' ? (
              <FaCamera />
          ) : (
              <FaCameraRetro />
          )}
      </button>
    </div>
  );
};

export default QRCodeScanner;
