import React from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';

const DiscountTable = ({ discounts, handleRowClick, isLoading }) => {

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        const day = date.getDate();
        const daySuffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return formattedDate.replace(day, day + daySuffix);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
            {discounts.map(discount => (
                <div onClick={() => handleRowClick(discount)} className="cursor-pointer shadow-sm flex flex-col w-full bg-white rounded-md p-4">
                    <div className="relative w-full h-24 overflow-hidden">
                        <img
                            src={discount.image_url}
                            alt=""
                            className="absolute inset-0 w-full h-full object-cover rounded-md"
                        />
                    </div>
                    <p className="text-[14px] text-black font-medium">{discount.name}</p>
                    <p className="text-black font-light text-[11px]">
                        {formatDate(discount.expiry_date)} <span className="mx-2">·</span> {discount.percentage_discount}% discount
                    </p>
                    <button onClick={() => handleRowClick(discount)} className="w-full items-center py-1.5 text-white text-[13px] font-medium rounded-md mt-2 bg-primary">Details</button>
                </div>
            ))}
        </div>
    );
};

export default DiscountTable;
