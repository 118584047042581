import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../../components/Layout';
import { getCookie } from '../../utils/cookieUtils';

const EditableDiscountComponent = () => {
  const [discount, setDiscount] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedImage, setEditedImage] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const discountId = id;

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const response = await axios.get(`https://api.discoun3ree.com/api/discounts/${discountId}`);
        setDiscount(response.data);
        setEditedName(response.data.name);
        setEditedImage(response.data.image_url);
        setEditedDescription(response.data.description);
      } catch (error) {
        console.error('Error fetching discount:', error);
      }
    };
    fetchDiscount();
  }, [discountId]);

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post('https://api.discoun3ree.com/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setEditedImage(response.data.url);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const accessToken = getCookie('access_token'); 

      const response = await axios.patch(`https://api.discoun3ree.com/api/discounts/${discountId}`, {
        name: editedName,
        image_url: editedImage,
        description: editedDescription,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data); 

      toast('Discount updated successfully!');
    } catch (error) {
      console.error('Failed to update discount:', error);
      toast.error('Failed to update discount.');
    }
  };

  if (!discount) {
    return <div className="p-4">Loading...</div>;
  }

  return (
    <Layout>
      <div className="w-full py-8 px-[5%] bg-gray-100 rounded-lg overflow-y-auto">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/3 p-4">
            <label className="flex text-gray-700 mb-2">Image</label>
            {loading ? (
              <p>Loading image...</p>
            ) : (
              <img src={editedImage} alt="Discount Image" className="rounded-md h-auto w-full mb-2" />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full px-3 py-2 border-b border-primary mb-2 bg-white"
            />
          </div>
          <div className="w-ful md:w-2/3 p-4">
            <h2 className="text-[20px] font-medium mb-4">Edit Discount</h2>
            <div className="mb-4">
              <label className="text-[14px] text-black">Name</label>
              <input
                type="text"
                className="w-full px-3 py-2 border-b border-primary text-primary font-light text-[13px] mb-2 bg-white outline-none"
                value={editedName}
                onChange={handleNameChange}
              />
            </div>
            <div className="mb-4">
              <label className="text-[14px] text-black">Description:</label>
              <textarea
                className="w-full px-3 py-2 h-[150px] border-b border-primary text-primary font-light text-[13px] mb-2 bg-white outline-none"
                value={editedDescription}
                onChange={handleDescriptionChange}
              />
            </div>
            <button
              className="bg-primary text-white px-6 py-1.5 rounded-md"
              disabled={loading}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditableDiscountComponent;
