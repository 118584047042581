import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { BsChatDots } from 'react-icons/bs';
import { CiBookmark, CiCalendarDate, CiEdit, CiGlobe } from 'react-icons/ci';
import { FaRegStar } from 'react-icons/fa';
import { FcSupport } from "react-icons/fc";
import { MdOutlineDashboardCustomize, MdOutlineDiscount, MdOutlineMiscellaneousServices, MdPendingActions } from 'react-icons/md';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoHomeOutline } from 'react-icons/io5';
import { useAuth } from '../contex/AuthContext';
import { fetchOwnerStores } from '../services/api';
import { getCookie } from '../utils/cookieUtils';
import { FiUsers } from 'react-icons/fi';

const Topmenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const token = getCookie('access_token');
  const location = useLocation();

  const isActive = (path) => location.pathname === path ? 'bg-[#F9EBD6]' : '';

  const logoutUser = () => {
    const domain = window.location.hostname === 'localhost' ? '' : '; domain=.discoun3ree.com';
    document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;${domain}; secure; SameSite=None`;
    navigate('/');
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (token) {
      fetchOwnerStores(token, setStores);
    }
  }, [token]);

  return (
    <>
      <nav className="bg-gradient-to-r from-yellow-50 to-primary w-full text-white px-[5%] h-[8vh] flex items-center tracking-wide">
        <div className="flex w-full justify-between items-center">
          <button
            onClick={toggleMenu}
            className="text-gray-700 block md:hidden focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
          <Link to='/' className="text-black text-center uppercase md:text-start text-[20px] italic font-medium">Discoun3</Link>
          <div className="hidden text-black md:flex items-center space-x-4">
            <Link to='https://discoun3ree.com' target='' className='text-black text-[14px] font-medium hover:underline hover:text-white'>Home</Link>
            <Link to='/' className='text-black text-[14px] font-medium hover:underline hover:text-white'>Index</Link>
            <Link to='https://discoun3ree.com/deals' target='' className='text-black text-[14px] font-medium hover:underline hover:text-white'>All Offers</Link>
            <Link to={`/merchant/${id}/offers`} className='text-black text-[14px] font-medium hover:underline hover:text-white'>Your Offers</Link>
            {user ? (
              stores?.length !== 0 ?
                <>
                  <Link to={`/merchant/${stores[0]?.id}/dashboard`} className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md '>Portal</Link>
                </> :
                <>
                  <Link to='/merchant/set-up-business' className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md '>Set Up Business</Link>
                </>
            ) : (
              <>
                <Link to='/accounts/login' className='text-white ml-4 text-[14px] font-medium hover:underline hover:text-white'>Login</Link>
                <Link to='/accounts/register' className='text-black text-[14px] font-medium hover:underline hover:text-primary bg-white px-6 py-1.5 rounded-md '>Sign Up</Link>
              </>
            )}
          </div>
        </div>
      </nav>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        ></div>
      )}

      <div
        className={`fixed top-0 left-0 h-full w-3/4 max-w-xs overflow-y-auto bg-white z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out`}
      >
        <div className="uppercase mt-4 pl-4">discoun3</div>
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <FaTimes size={20} />
        </button>
        <nav className="flex flex-col space-y-4 p-4">
          <Link to={`/`} className={`flex items-center mt-4 gap-2 p-2 rounded-md ${isActive('/')}`}>
            <IoHomeOutline />
            <span className='flex text-[#242220] text-[14px]'>Home</span>
          </Link>
          <Link to={`/merchant/${id}/dashboard`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/dashboard`)}`}>
            <MdOutlineDashboardCustomize />
            <span className='flex text-[#242220] text-[14px]'>Dashboard</span>
          </Link>
          <Link to={`/merchant/${id}/offers`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/offers`)}`}>
            <MdOutlineDiscount />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Offers</span>
          </Link>
          <Link to={`/merchant/${id}/services`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/services`)}`}>
            <MdOutlineMiscellaneousServices />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Services</span>
          </Link>
          <Link to={`/merchant/${id}/appointments`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/appointments`)}`}>
            <CiBookmark />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Appointments</span>
          </Link>
          <Link to={`/merchant/${id}/pending`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/pending`)}`}>
            <MdPendingActions />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Pending</span>
          </Link>
          <Link to={`/merchant/${id}/chat`} className={`flex items-center gap-2 p-2 rounded-md ${isActive('/chat')}`}>
            <BsChatDots />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Chat</span>
          </Link>
          <Link to={`/merchant/${id}/reviews`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/reviews`)}`}>
            <FaRegStar />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Reviews</span>
          </Link>
          <Link to={`/merchant/${id}/socials`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/socials`)}`}>
            <CiGlobe />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Socials</span>
          </Link>
          <Link to={`/merchant/edit/${id}`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/edit/${id}`)}`}>
            <CiEdit />
            <span className='flex text-[#777777] text-[14px] group group-hover:text-[#242220]'>Edit</span>
          </Link>
          <Link to={`/merchant/${id}/staff`} className={`flex items-center gap-2 p-2 rounded-md ${isActive(`/merchant/${id}/staff`)}`}>
            <FiUsers />
            <span className='flex text-black text-[14px] group group-hover:text-[#242220]'>Staff</span>
          </Link>
          <button onClick={logoutUser} className="flex items-center mt-4 bg-primary text-white text-[14px] font-medium gap-2 p-2 rounded-md text-center w-full mx-auto justify-center">
            Log Out
          </button>
        </nav>
      </div>
    </>
  );
};

export default Topmenu;
